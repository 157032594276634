import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Skeleton, Snackbar, Alert, Button, TextField, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Grid, IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import axios from 'axios';
import { CommonSX, orangeColor } from '../../css/common_sx.js';
import { searchFunction } from '../../common/search_function';
import CloseIcon from '@mui/icons-material/Close';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import LoadingBackdrop from '../../common/loading_backdrop';
import CommentIcon from '@mui/icons-material/Comment';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { debounce } from 'lodash';

function CleaningDetailDialog({ open, onClose, setJob, job }) {
    const [jobTime, setJobTime] = useState(null);
    const [cleanerComment, setCleanerComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        setJobTime(new Date(job?.job_timed || ''));
        setCleanerComment(job?.cleaner_comment || '');
    }, [job]);

    const handleCleanerCommentChange = (event) => {
        setCleanerComment(event.target.value);
    };

    const handleClose = () => {
        onClose();
    };


    const handleSave = () => {
        if ((new Date(job.job_timed).getDate() < new Date().getDate())) {
            handleClose();
        }
        setLoading(true);
        axios.put(`/Office/Schedules/Cleaning/update_job/${job.internal_job_ref}`, { cleaner_comment: cleanerComment, job_timed: jobTime })
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    const updatedJob = {
                        ...job,
                        job_timed: jobTime,
                        cleaner_comment: cleanerComment
                    };
                    setJob(updatedJob);
                    handleClose();
                } else {
                    setLoading(false);
                    setError(true);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                console.error("Error saving data:", err);
            })


    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle sx={{ bgcolor: orangeColor, color: '#fff' }}>
                {job?.type_name} - {job?.ref}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#fff',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    {/* Property Name */}
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Ref Date:</strong> {job?.job_timed != null ? new Date(job?.job_timed).toLocaleString() : 'Not Set'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Property:</strong> {job?.phy_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Address:</strong> {job?.property_address}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Bedrooms:</strong> {job?.no_of_bedroom}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Bathrooms:</strong> {job?.no_of_bathroom}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Apartment Door Code:</strong> {job?.ap_door_code}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Main Door Code:</strong> {job?.main_door_code}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Cleaner:</strong> {job?.cleaner}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TimePicker
                            label="Job Timing"
                            value={jobTime}
                            ampm={false}
                            onChange={(value) => setJobTime(value)}
                            views={['hours', 'minutes']}
                            readOnly={(new Date(job?.job_timed).getDate() < new Date().getDate())}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Memo:</strong> {job?.memo}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Job Comment"
                            multiline
                            rows={3}
                            value={job?.memo}
                            InputProps={{
                                readOnly: (new Date(job?.job_timed).getDate() < new Date().getDate()),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Cleaner Comment"
                            multiline
                            rows={3}
                            value={cleanerComment}
                            onChange={handleCleanerCommentChange}
                            inputProps={{
                                readOnly: (new Date(job?.job_timed).getDate() < new Date().getDate())
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
            <LoadingBackdrop open={loading} text="Saving Time and comment" />
            <Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)}>
                <Alert severity="info" sx={{ width: '100%' }}>Error saving Data</Alert>
            </Snackbar>
        </Dialog>
    );
}


function CleaningSchedule() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [globalSearch, setGlobalSearch] = useState('');
    const [endDate, setEndDate] = useState(() => {
        const initialEndDate = new Date();
        initialEndDate.setDate(initialEndDate.getDate() + 14);
        return initialEndDate;
    });
    const [error, setError] = useState(false);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const getDate = (date) => date.toLocaleDateString('en-US');

    const fetchdata = async (date_from, date_to) => {
        const formattedStartDate = getDate(date_from);
        const formattedEndDate = getDate(date_to);
        setLoading(true);
        try {
            let response = await axios.get(`/Office/Schedules/Cleaning?date_from=${formattedStartDate}&date_to=${formattedEndDate}`);
            setJobs(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateJob = (updatedJob) => {
        setJobs((prevJobs) =>
            prevJobs.map(job => job.ref === updatedJob.ref ? updatedJob : job)
        );
    };

    useEffect(() => {
        fetchdata(startDate, endDate);
    }, [startDate, endDate]);

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        fetchdata(newDate, endDate);
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
        fetchdata(startDate, newDate);
    };

    const downloadCsv = async () => {
        try {
            const formattedStartDate = getDate(startDate);
            const formattedEndDate = getDate(endDate);
            const url = `/Office/Schedules/Cleaning?date_from=${formattedStartDate}&date_to=${formattedEndDate}&type=csv`;

            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data]);
            const urlObject = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = 'downloadedFile.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading CSV:', error);
            setError(true);
        }
    };

    const handleCloseError = () => setError(false);

    // Helper to group jobs by date and cleaner
    const groupJobsByDateAndCleaner = (jobs) => {
        const groupedJobs = {};
        jobs.forEach(job => {
            const date = job.ref_date;
            const cleaner = job.cleaner;

            if (!groupedJobs[date]) {
                groupedJobs[date] = {};
            }

            if (!groupedJobs[date][cleaner]) {
                groupedJobs[date][cleaner] = [];
            }

            groupedJobs[date][cleaner].push(job);
        });

        return groupedJobs;
    };

    const filteredJobs = searchFunction(globalSearch, ['phy_name', 'property_address', 'property_status', 'cleaner', 'date', 'ref'], jobs);

    const groupedJobs = groupJobsByDateAndCleaner(filteredJobs);

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" margin="5px">
            <Box display="flex" flexDirection="row" alignItems="center" mb={1} bgcolor={orangeColor} p={1} borderRadius={1}>
                <Typography marginRight={1} variant="body2">Date From:</Typography>
                <DatePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                    format="yyyy-MM-dd"
                    sx={{
                        ...CommonSX,
                        '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
                    }}
                    renderInput={(params) => <TextField {...params} margin="dense" size="small" />}
                />
                <Typography marginLeft={2} marginRight={1} variant="body2">Date To:</Typography>
                <DatePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                    format="yyyy-MM-dd"
                    sx={{
                        ...CommonSX,
                        '& .MuiInputBase-root': { fontSize: '0.875rem', padding: '4px 8px' }
                    }}
                    renderInput={(params) => <TextField {...params} margin="dense" size="small" />}
                />
                <TextField
                    label="Search by Name"
                    variant="outlined"
                    size="small"
                    value={globalSearch}
                    fullWidth
                    onChange={(e) => setGlobalSearch(e.target.value)}
                    sx={{
                        bgcolor: 'white',
                        borderRadius: 1
                    }}
                />
                <Button
                    variant="contained"
                    onClick={downloadCsv}
                    size="small"
                    sx={{ ml: 2, fontSize: '0.75rem', padding: '4px 8px' }}
                >
                    Download CSV
                </Button>
            </Box>
            <Box position="relative" width="100%" height="100%">
                <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0">
                    <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>Type of Job</TableCell>
                                    <TableCell align='center'>Reference</TableCell>
                                    <TableCell align='center'>Name of Property</TableCell>
                                    <TableCell align='center'><BedIcon /></TableCell>
                                    <TableCell align='center'><BathtubIcon /></TableCell>
                                    <TableCell align='center'>Address</TableCell>
                                    <TableCell align='center'>Time of Checkout</TableCell>
                                    <TableCell align='center'>Job Timed</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                    <TableCell align='center'>Apartment door code</TableCell>
                                    <TableCell align='center'>Main door code</TableCell>
                                    <TableCell align='center'>Memo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            <Skeleton variant="rectangular" height={40} sx={{ bgcolor: 'white' }} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    Object.keys(groupedJobs).map((date) => {
                                        const cleaners = Object.keys(groupedJobs[date]);
                                        const showCleanerHeader = cleaners.length > 1;
                                        return (
                                            <React.Fragment key={date}>
                                                <TableRow sx={{ bgcolor: '#f0f0f0' }}>
                                                    <TableCell sx={{ bgcolor: orangeColor }} colSpan={12}><b>{date}</b></TableCell>
                                                </TableRow>
                                                {cleaners.map((cleaner) => (
                                                    <React.Fragment key={cleaner}>
                                                        {showCleanerHeader && (
                                                            <TableRow sx={{ bgcolor: 'red' }}>
                                                                <TableCell colSpan={12}><SubdirectoryArrowRightIcon /><b>{cleaner}</b></TableCell>
                                                            </TableRow>
                                                        )}
                                                        {groupedJobs[date][cleaner].map((job, idx) => (
                                                            <TableRow
                                                                key={job.ref}
                                                                onClick={() => {
                                                                    setSelectedJob(job);
                                                                    setOpenDetailDialog(true);
                                                                }}
                                                                hover
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <TableCell>{job.type_name}</TableCell>
                                                                <TableCell>{job.ref}</TableCell>
                                                                <TableCell>{job.phy_name}</TableCell>
                                                                <TableCell>{job.no_of_bedroom}</TableCell>
                                                                <TableCell>{job.no_of_bathroom}</TableCell>
                                                                <TableCell>{job.property_address}</TableCell>
                                                                <TableCell>{job.time_checkout}</TableCell>
                                                                <TableCell>{(job.cleaner_comment != null && job.cleaner_comment.length >= 1) ? <CommentIcon /> : ''}{job.job_timed ? new Date(job.job_timed).toLocaleTimeString() : ''}</TableCell>
                                                                <TableCell>{job.property_status}</TableCell>
                                                                <TableCell>{job.ap_door_code}</TableCell>
                                                                <TableCell>{job.main_door_code}</TableCell>
                                                                <TableCell>{job.memo}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <CleaningDetailDialog open={openDetailDialog} onClose={() => setOpenDetailDialog(false)} job={selectedJob} setJob={handleUpdateJob} />
            <Snackbar open={error} autoHideDuration={3000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Error fetching data!
                </Alert>
            </Snackbar>
        </Box>

    );
}

export default CleaningSchedule;

