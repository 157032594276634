import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import axios from 'axios';
import './ZItem.css';
import { PropertiesList } from '../properties/basicsidebar';
import { Sidebar } from 'primereact/sidebar';
const PeriodType = [{ name: 'Weekly', value: 2 }, { name: 'Monthly', value: 3 }];
const CompareType = [{ name: 'None', value: 0 }, { name: 'Last Year', value: 1 }, { name: 'Last 2 Year', value: 2 }, { name: 'Last 3 Year', value: 3 }];
import LoadingAnimation from '../../common/loading';

function totalYearly(data) {
    return Object.values(data).reduce((acc, period) => {
        acc.guestPrice += period.guestPrice;
        acc.commi += period.commi;
        acc.cleaningFee += period.cleaningFee;
        acc.profit += period.profit;
        return acc;
    }, { guestPrice: 0, commi: 0, cleaningFee: 0, profit: 0 });
}

function groupDataByPeriod(reservationData, cleaningData, dates) {
    let grouped = {};
    dates.forEach(datePeriod => {
        grouped[datePeriod.label] = {
            guestPrice: 0,
            commi: 0,
            cleaningFee: 0,
            profit: 0
        };
    });

    reservationData.forEach(item => {
        const itemDate = new Date(item.date_from);
        dates.forEach(datePeriod => {
            if (itemDate >= datePeriod.date_from && itemDate <= datePeriod.date_to) {
                grouped[datePeriod.label].guestPrice += parseFloat(item.guest_price.replace('$', '').replace(',', ''));
                grouped[datePeriod.label].commi += parseFloat(item.commi || 0);
            }
        });
    });

    cleaningData.forEach(item => {
        const itemDate = new Date(item.job_timed);
        dates.forEach(datePeriod => {
            if (itemDate >= datePeriod.date_from && itemDate <= datePeriod.date_to) {
                grouped[datePeriod.label].cleaningFee += parseFloat(item.worker_fees.replace('$', '').replace(',', ''));
            }
        });
    });

    Object.keys(grouped).forEach(label => {
        grouped[label].profit = grouped[label].guestPrice - grouped[label].commi - grouped[label].cleaningFee;
    });
    return grouped;
};

function generateDates(year, periodType) {
    let dates = [];
    let currentDate = new Date(year, 0, 1);
    let endDate = new Date(year, 11, 31);
    const getWeekNumber = (date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };
    while (currentDate <= endDate) {
        let dateFrom = new Date(currentDate);
        let dateTo;
        let label;

        switch (periodType) {
            case 1: // Daily
                dateTo = new Date(currentDate);
                label = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(dateFrom);
                currentDate.setDate(currentDate.getDate() + 1);
                break;

            case 2: // Weekly
                dateTo = new Date(currentDate);
                dateTo.setDate(currentDate.getDate() + 6);
                label = `${new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(dateFrom)} Week ${getWeekNumber(dateFrom)}`;
                currentDate.setDate(currentDate.getDate() + 7);
                break;
            case 3: // Monthly
                dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                label = Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(dateFrom);
                currentDate.setMonth(currentDate.getMonth() + 1);
                break;
            default:
                return [];
        }

        dates.push({
            date_from: dateFrom,
            date_to: dateTo,
            label: label
        });
    }
    return dates;
};

function ZItem({ property_name, property_id, year, periodType, compareType, fetchedData }) {
    const [groupedData, setGroupedData] = useState({});
    const [groupedCompareData, setGroupedCompareData] = useState({});

    const { reservationData, cleaningData, compareReservationData, compareCleaningData } = fetchedData;



    const calculatePercentageDifference = (currentValue, compareValue) => {
        if (compareValue === 0) {
            return currentValue > 0 ? 100 : 0;
        }
        return ((currentValue - compareValue) / compareValue) * 100;
    };

    useEffect(() => {
        if (reservationData.length > 0) {
            const dates = generateDates(year, periodType);
            const grouped = groupDataByPeriod(reservationData, cleaningData, dates);
            setGroupedData(grouped);

            if (compareReservationData.length > 0) {
                const compareDates = generateDates(year - compareType, periodType);
                const groupedCompare = groupDataByPeriod(compareReservationData, compareCleaningData, compareDates);
                const adjustedGroupedCompare = {};
                Object.keys(grouped).forEach((label, index) => {
                    const compareLabel = Object.keys(groupedCompare)[index];
                    adjustedGroupedCompare[label] = groupedCompare[compareLabel] || { guestPrice: 0, commi: 0, cleaningFee: 0, profit: 0 };
                });
                setGroupedCompareData(adjustedGroupedCompare);
            } else {
                setGroupedCompareData({});
            }
        }
    }, [reservationData, compareReservationData, cleaningData, compareCleaningData, periodType]);

    const dates = generateDates(year, periodType);


    const currentYearTotal = totalYearly(groupedData);
    const compareYearTotal = totalYearly(groupedCompareData);

    return (
        <Box sx={{ borderRadius: '16px', borderColor: 'gray', border: .2 }} p={2} mb={1} mr={1}>
            <Typography>{property_name}</Typography>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th style={{ minWidth: '200px' }}>Metric</th>
                        {dates.map((date, index) => (
                            <th style={{ minWidth: '100px' }} key={index}>{date.label}</th>
                        ))}
                        <th style={{ minWidth: '150px' }}>Total Year</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Guest Paid</td>
                        {dates.map((date, index) => (
                            <td key={index}>{groupedData[date.label]?.guestPrice?.toFixed(2) || '-'}</td>
                        ))}
                        <td>{currentYearTotal.guestPrice.toFixed(2)}</td>
                    </tr>
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Compare with ${CompareType.find((item) => item.value === compareType).name}`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>{groupedCompareData[date.label]?.guestPrice?.toFixed(2) || '-'}</td>
                            ))}
                            <td>{compareYearTotal.guestPrice.toFixed(2)}</td>
                        </tr>
                    )}
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Guest Price Difference(%)`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>
                                    <span style={{
                                        color: calculatePercentageDifference(groupedData[date.label]?.guestPrice, groupedCompareData[date.label]?.guestPrice) >= 0 ? 'green' : 'red'
                                    }}>
                                        {calculatePercentageDifference(groupedData[date.label]?.guestPrice, groupedCompareData[date.label]?.guestPrice).toFixed(2)}%
                                    </span>
                                </td>
                            ))}
                            <td>
                                <span style={{
                                    color: calculatePercentageDifference(currentYearTotal.guestPrice, compareYearTotal.guestPrice) >= 0 ? 'green' : 'red'
                                }}>
                                    {calculatePercentageDifference(currentYearTotal.guestPrice, compareYearTotal.guestPrice).toFixed(2)}%
                                </span>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Channel Commission</td>
                        {dates.map((date, index) => (
                            <td key={index}>{groupedData[date.label]?.commi?.toFixed(2) || '-'}</td>
                        ))}
                        <td>{currentYearTotal.commi.toFixed(2)}</td>
                    </tr>
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Compare with ${CompareType.find((item) => item.value === compareType).name}`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>{groupedCompareData[date.label]?.commi?.toFixed(2) || '-'}</td>
                            ))}
                            <td>{compareYearTotal.commi.toFixed(2)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Cleaning Fee</td>
                        {dates.map((date, index) => (
                            <td key={index}>{groupedData[date.label]?.cleaningFee?.toFixed(2) || '-'}</td>
                        ))}
                        <td>{currentYearTotal.cleaningFee.toFixed(2)}</td>
                    </tr>
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Compare with ${CompareType.find((item) => item.value === compareType).name}`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>{groupedCompareData[date.label]?.cleaningFee?.toFixed(2) || '-'}</td>
                            ))}
                            <td>{compareYearTotal.cleaningFee.toFixed(2)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Property Rent Value</td>
                        {dates.map((date, index) => (
                            <td key={index}>{0}</td>
                        ))}
                        <td>{0}</td>
                    </tr>
                    <tr style={{ marginTop: '5px' }}>
                        <td>Profit</td>
                        {dates.map((date, index) => (
                            <td key={index}>{groupedData[date.label]?.profit?.toFixed(2) || '-'}</td>
                        ))}
                        <td>{currentYearTotal.profit.toFixed(2)}</td>
                    </tr>
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Compare with ${CompareType.find((item) => item.value === compareType).name}`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>{groupedCompareData[date.label]?.profit?.toFixed(2) || '-'}</td>
                            ))}
                            <td>{compareYearTotal.profit.toFixed(2)}</td>
                        </tr>
                    )}
                    {compareType !== 0 && (
                        <tr>
                            <td>{`Profit Difference(%)`}</td>
                            {dates.map((date, index) => (
                                <td key={index}>
                                    <span style={{
                                        color: calculatePercentageDifference(groupedData[date.label]?.profit, groupedCompareData[date.label]?.profit) >= 0 ? 'green' : 'red'
                                    }}>
                                        {calculatePercentageDifference(groupedData[date.label]?.profit, groupedCompareData[date.label]?.profit).toFixed(2)}%
                                    </span>
                                </td>
                            ))}
                            <td>
                                <span style={{
                                    color: calculatePercentageDifference(currentYearTotal.profit, compareYearTotal.profit) >= 0 ? 'green' : 'red'
                                }}>
                                    {calculatePercentageDifference(currentYearTotal.profit, compareYearTotal.profit).toFixed(2)}%
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Box>
    );
}

function ZReportSummary({ visible, onClose, fetchedData, selectedProperties, selectedPeriodType, selectedCompareType }) {
    const [summary, setSummary] = useState({ totalGuestPrice: 0, totalCommi: 0, totalCleaningFee: 0, totalProfit: 0 });
    const [compareSummary, setCompareSummary] = useState({ totalGuestPrice: 0, totalCommi: 0, totalCleaningFee: 0, totalProfit: 0 });

    useEffect(() => {
        if (visible) {
            console.debug('Summary: ', fetchedData);
            const newSummary = selectedProperties.reduce((acc, propertyId) => {
                console.debug(propertyId);
                const { reservationData, cleaningData } = fetchedData[propertyId.property_id];
                const currentYearTotal = totalYearly(groupDataByPeriod(reservationData, cleaningData, generateDates(2024, selectedPeriodType)));
                acc.totalGuestPrice += currentYearTotal.guestPrice;
                acc.totalCommi += currentYearTotal.commi;
                acc.totalCleaningFee += currentYearTotal.cleaningFee;
                acc.totalProfit += currentYearTotal.profit;
                return acc;
            }, { totalGuestPrice: 0, totalCommi: 0, totalCleaningFee: 0, totalProfit: 0 });

            setSummary(newSummary);

            if (selectedCompareType !== 0) {
                const newCompareSummary = selectedProperties.reduce((acc, propertyId) => {
                    const { compareReservationData, compareCleaningData } = fetchedData[propertyId.property_id];
                    const compareYearTotal = totalYearly(groupDataByPeriod(compareReservationData, compareCleaningData, generateDates(2024 - selectedCompareType, selectedPeriodType)));
                    acc.totalGuestPrice += compareYearTotal.guestPrice;
                    acc.totalCommi += compareYearTotal.commi;
                    acc.totalCleaningFee += compareYearTotal.cleaningFee;
                    acc.totalProfit += compareYearTotal.profit;
                    return acc;
                }, { totalGuestPrice: 0, totalCommi: 0, totalCleaningFee: 0, totalProfit: 0 });

                setCompareSummary(newCompareSummary);
            } else {
                setCompareSummary({ totalGuestPrice: 0, totalCommi: 0, totalCleaningFee: 0, totalProfit: 0 });
            }
        }
    }, [visible, selectedProperties, fetchedData, selectedPeriodType, selectedCompareType]);

    const calculatePercentageDifference = (currentValue, compareValue) => {
        if (compareValue === 0) {
            return currentValue > 0 ? 100 : 0;
        }
        return ((currentValue - compareValue) / compareValue) * 100;
    };

    return (
        <Sidebar position='right' style={{ width: '40vw' }} visible={visible} onHide={onClose}>
            <Box>
                <Typography variant="h6">Global Summary</Typography>
                <Typography>Total Guest Price: {summary.totalGuestPrice.toFixed(2)}</Typography>
                <Typography>Total Commission: {summary.totalCommi.toFixed(2)}</Typography>
                <Typography>Total Cleaning Fee: {summary.totalCleaningFee.toFixed(2)}</Typography>
                <Typography>Total Profit: {summary.totalProfit.toFixed(2)}</Typography>

                {selectedCompareType !== 0 && (
                    <>
                        <Typography variant="h6">Comparison Summary</Typography>
                        <Typography>Total Guest Price (Comparison): {compareSummary.totalGuestPrice.toFixed(2)}</Typography>
                        <Typography>Total Commission (Comparison): {compareSummary.totalCommi.toFixed(2)}</Typography>
                        <Typography>Total Cleaning Fee (Comparison): {compareSummary.totalCleaningFee.toFixed(2)}</Typography>
                        <Typography>Total Profit (Comparison): {compareSummary.totalProfit.toFixed(2)}</Typography>
                        <Typography variant="h6">Difference (%)</Typography>
                        <Typography>Guest Price Difference:
                            <span style={{
                                color: calculatePercentageDifference(summary.totalGuestPrice, compareSummary.totalGuestPrice) >= 0 ? 'green' : 'red'
                            }}>
                                {calculatePercentageDifference(summary.totalGuestPrice, compareSummary.totalGuestPrice).toFixed(2)}%
                            </span>
                        </Typography>
                        <Typography>Commission Difference:
                            <span style={{
                                color: calculatePercentageDifference(summary.totalCommi, compareSummary.totalCommi) >= 0 ? 'green' : 'red'
                            }}>
                                {calculatePercentageDifference(summary.totalCommi, compareSummary.totalCommi).toFixed(2)}%
                            </span>
                        </Typography>
                        <Typography>Cleaning Fee Difference:
                            <span style={{
                                color: calculatePercentageDifference(summary.totalCleaningFee, compareSummary.totalCleaningFee) >= 0 ? 'green' : 'red'
                            }}>
                                {calculatePercentageDifference(summary.totalCleaningFee, compareSummary.totalCleaningFee).toFixed(2)}%
                            </span>
                        </Typography>
                        <Typography>Profit Difference:
                            <span style={{
                                color: calculatePercentageDifference(summary.totalProfit, compareSummary.totalProfit) >= 0 ? 'green' : 'red'
                            }}>
                                {calculatePercentageDifference(summary.totalProfit, compareSummary.totalProfit).toFixed(2)}%
                            </span>
                        </Typography>
                    </>
                )}
            </Box>
        </Sidebar>
    );
}

export default function ZReport() {
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedPeriodType, setSelectedPeriodType] = useState(3);
    const [selectedCompareType, setSelectedCompareType] = useState(0);
    const [overallDifference, setOverallDifference] = useState(false);
    const [showGlobalSummary, setShowGlobalSummary] = useState(false);
    const [fetchedData, setFetchedData] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchPropertyData = async (propertyId) => {
        const year = 2024;

        const fetchData = async (year) => {
            const { data } = await axios.get(`/Office/Statistics/ZReport/${propertyId}/${year}`);
            return data;
        };
        const fetchCleaningData = async (year) => {
            const { data } = await axios.get(`/Office/Statistics/ZReport/${propertyId}/${year}/cleaningjobs`);
            return data;
        };

        const [reservationData, cleaningData] = await Promise.all([fetchData(year), fetchCleaningData(year)]);
        let compareReservationData = [], compareCleaningData = [];
        if (selectedCompareType !== 0) {
            const compareYear = year - selectedCompareType;
            [compareReservationData, compareCleaningData] = await Promise.all([fetchData(compareYear), fetchCleaningData(compareYear)]);
        }
        return { reservationData, cleaningData, compareReservationData, compareCleaningData };
    };

    const handleSelect = async (items) => {
        setLoading(true);
        const newFetchedData = { ...fetchedData };
        for (let item of items) {
            if (!newFetchedData[item.property_id]) {
                const propertyData = await fetchPropertyData(item.property_id);
                newFetchedData[item.property_id] = propertyData;
            }
        }
        setSelectedProperties(items);
        setFetchedData(newFetchedData);
        setLoading(false);
        console.debug("API FETCH RES:", fetchedData);
    };

    useEffect(() => {
        const refetchDataForCompareType = async () => {
            setLoading(true);
            const newFetchedData = { ...fetchedData };
            for (let item of selectedProperties) {
                const propertyData = await fetchPropertyData(item.property_id);
                newFetchedData[item.property_id] = propertyData;
            }
            setFetchedData(newFetchedData);
            setLoading(false);
            console.debug("API FETCH RES:", fetchedData);
        };

        if (selectedProperties.length > 0) {
            refetchDataForCompareType();
        }
    }, [selectedCompareType]);

    return (
        <Box display="flex" flexDirection="row" width="100%" height="100vh" flex={1}>
            <Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
                <Box>
                    <Typography variant="h6">Z Report</Typography>
                </Box>
                <PropertiesList onSelect={handleSelect} />
            </Box>
            <Box flex="5" width="100%" height="100%" display="flex" flexDirection="column">
                <Box justifyContent="start" alignItems="center" display="flex" flexDirection="row">
                    <Typography variant="h6" marginRight="16px">Period Type:</Typography>
                    <SelectButton value={selectedPeriodType} onChange={(e) => setSelectedPeriodType(e.value)} optionLabel="name" options={PeriodType} optionValue="value" />
                    <Typography variant="h6" marginRight="16px">Compare Type:</Typography>
                    <Dropdown value={selectedCompareType} onChange={(e) => setSelectedCompareType(e.value)} options={CompareType} optionLabel="name" optionValue="value" />
                    <Typography variant="h6" marginLeft="5px" marginRight="16px">Calc overall difference</Typography>
                    <Checkbox checked={overallDifference} onChange={e => setOverallDifference(e.checked)} />
                    <Button onClick={() => setShowGlobalSummary(true)}>Show Global Summary</Button>
                </Box>
                <Box position="relative" width="100%" height="100%">
                    {loading ? <LoadingAnimation loading={loading} loadingText="Loadng Properties" /> :
                        <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0">
                            {selectedProperties.map((item, index) => (
                                <ZItem
                                    key={`ZREP${item.property_id}`}
                                    property_id={item.property_id}
                                    year={(new Date().getFullYear())}
                                    periodType={selectedPeriodType}
                                    compareType={selectedCompareType}
                                    property_name={item.name}
                                    fetchedData={fetchedData[item.property_id]}
                                />
                            ))}
                        </Box>}
                </Box>
            </Box>
            <ZReportSummary
                onClose={() => setShowGlobalSummary(false)}
                visible={showGlobalSummary}
                fetchedData={fetchedData}
                selectedProperties={selectedProperties}
                selectedPeriodType={selectedPeriodType}
                selectedCompareType={selectedCompareType}
            />
        </Box>
    );
}
