import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Skeleton, TablePagination, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
import { debounce } from 'lodash';

export default function CleaningDefaultTypePriceEditor() {
    const [globalSearch, setGlobalSearch] = useState('');
    const [defaults, setDefaults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cleaners, setCleaners] = useState([]);
    const [selectedCleaner, setSelectedCleaner] = useState(null);
    const [cleaningTypes, setCleaningTypes] = useState([]);
    const [selectedCleaningType, setSelectedCleaningType] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    // State to track pending changes
    const [pendingChanges, setPendingChanges] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false); // For the confirmation dialog

    const fetchCleaners = async () => {
        axios.get('/Office/Schedules/CleaningDefaults/Cleaners')
            .then((response) => {
                if (response.status === 200) {
                    setCleaners(response.data);
                }
            })
            .catch((err) => { });
    };

    const fetchCleaningTypes = async () => {
        axios.get('/Office/Schedules/CleaningDefaults/Types')
            .then((response) => {
                if (response.status === 200) {
                    setCleaningTypes(response.data);
                }
            })
            .catch((err) => { });
    };

    const fetchDefaultTypePrices = async () => {
        setLoading(true);
        axios.get(`/Office/Schedules/CleaningDefaults/defaults/${selectedCleaner}/${selectedCleaningType}`)
            .then((response) => {
                setLoading(false);
                setDefaults(response.data);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        if (selectedCleaner && selectedCleaningType) {
            fetchDefaultTypePrices();
        }
    }, [selectedCleaner, selectedCleaningType]);

    useEffect(() => {
        fetchCleaners();
        fetchCleaningTypes();
    }, []);

    const handlePriceChange = (index, newValue) => {
        const updatedDefaults = [...defaults];
        updatedDefaults[index].job_price = newValue;
        setDefaults(updatedDefaults);

        // Track changes in the pendingChanges state
        const changedItem = updatedDefaults[index];
        setPendingChanges((prev) => {
            const existingChangeIndex = prev.findIndex(item => item.property_id === changedItem.property_id);
            if (existingChangeIndex > -1) {
                const updatedChanges = [...prev];
                updatedChanges[existingChangeIndex] = changedItem;
                return updatedChanges;
            } else {
                return [...prev, changedItem];
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Memoize filtered defaults to avoid recalculating on each render
    const filteredDefaults = useMemo(() => {
        return searchFunction(globalSearch, ['name_alias'], defaults);
    }, [globalSearch, defaults]);

    // Debounce the search input to avoid filtering on every keystroke
    const handleSearchChange = useCallback(
        debounce((value) => {
            setGlobalSearch(value);
        }, 300), // wait 300ms before applying the search
        []
    );

    useEffect(() => {
        return () => {
            handleSearchChange.cancel();
        };
    }, [handleSearchChange]);

    // Open dialog to confirm changes
    const handleSaveClick = () => {
        setDialogOpen(true);
    };

    // Confirm changes and "save" them
    const handleConfirmChanges = () => {
        // Here you would make the API call to save the changes
        console.log('Saving changes:', pendingChanges);
        setPendingChanges([]); // Reset changes after saving
        setDialogOpen(false); // Close dialog
    };

    // Close the dialog without saving
    const handleCancelChanges = () => {
        setDialogOpen(false);
    };

    return (
        <Box display="flex" flexDirection="column" flex="1" width="100%" height="90%" margin="5px">
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }} mb={2} bgcolor={orangeColor} p={2} borderRadius={1} gap={2}>
                <Select
                    label="Cleaner"
                    name="cleaner"
                    autoWidth
                    variant="filled"
                    value={selectedCleaner || ''}
                    onChange={(e) => setSelectedCleaner(e.target.value)}
                    displayEmpty
                    sx={{ minWidth: 200 }}
                >
                    <MenuItem value="">
                        <em>Select Cleaner</em>
                    </MenuItem>
                    {cleaners.length > 0 ? cleaners.map((cleaner) => (
                        <MenuItem key={cleaner.id} value={cleaner.id}>
                            {cleaner.ro_comment}
                        </MenuItem>
                    )) : <MenuItem disabled>No cleaners available</MenuItem>}
                </Select>
                <Select
                    label="Cleaning Type"
                    name="cleaningType"
                    autoWidth
                    variant="filled"
                    value={selectedCleaningType || ''}
                    onChange={(e) => setSelectedCleaningType(e.target.value)}
                    displayEmpty
                    sx={{ minWidth: 200 }}
                >
                    <MenuItem value="">
                        <em>Select Cleaning Type</em>
                    </MenuItem>
                    {cleaningTypes.length > 0 ? cleaningTypes.map((cleaningType) => (
                        <MenuItem key={cleaningType.id} value={cleaningType.id}>
                            {cleaningType.type_name}
                        </MenuItem>
                    )) : <MenuItem disabled>No cleaning types available</MenuItem>}
                </Select>
                <TextField
                    label="Search by Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => handleSearchChange(e.target.value)} // debounce search input
                    sx={{
                        bgcolor: 'white',
                        borderRadius: 1
                    }}
                />
            </Box>

            <TableContainer component={Paper} style={{ maxHeight: '69vh', overflowY: 'auto' }}>
                <Table stickyHeader size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Property Name</TableCell>
                            <TableCell align='center'>No of Bedrooms</TableCell>
                            <TableCell align='center'>No of Bathrooms</TableCell>
                            <TableCell align='center'>Job Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                            <TableRow key={index}>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                                <TableCell><Skeleton variant="text" /></TableCell>
                            </TableRow>
                        )) : filteredDefaults
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow key={`${row.property_id}`}>
                                    <TableCell>{row.name_alias}</TableCell>
                                    <TableCell align="center">{row.no_of_bedroom}</TableCell>
                                    <TableCell align="center">{row.no_of_bathroom}</TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={row.job_price || ''}
                                            onChange={(e) => handlePriceChange(index, e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            sx={{ maxWidth: '100px' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Save Button */}
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                    sx={{ ...CommonSX, width: '200px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleSaveClick}
                    disabled={pendingChanges.length === 0} // Disable if no changes
                >
                    Save Changes
                </Button>
            </Box>

            {/* Confirmation Dialog */}
            <Dialog open={dialogOpen} onClose={handleCancelChanges}>
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogContent>
                    <List>
                        {pendingChanges.length > 0 ? pendingChanges.map((change, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`${change.name_alias}: ${change.job_price} USD`}
                                    secondary={`Bedrooms: ${change.no_of_bedroom}, Bathrooms: ${change.no_of_bathroom}`}
                                />
                            </ListItem>
                        )) : <ListItem>No changes to display.</ListItem>}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelChanges} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmChanges} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Pagination */}
            <TablePagination
                rowsPerPageOptions={[30, 50, 60]}
                component="div"
                sx={{ ...CommonSX, bgcolor: 'white' }}
                count={defaults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

