import {
    Search as SearchIcon,
    Home as HomeIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    House as HouseIcon,
    Group as FriendsIcon,
    Looks4Rounded
} from '@mui/icons-material';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import MoneyIcon from '@mui/icons-material/Money';
import { isActionEnabled, isActionVisible } from './common/securityfunctions';
import { getSecurityJson } from './common/securityfunctions';

async function filterMenuItems(items, secData) {
    const filteredItems = [];

    for (let item of items) {
        let isVisible = true;
        let isEnabled = true;

        if (item.action) {
            const [checkType, formName, actionName] = item.action.split(':');
            const actionParams = { json: secData.actions, formName, actionName };

            if (checkType === 'v') {
                isVisible = isActionVisible(actionParams);
            } else if (checkType === 'e') {
                isEnabled = isActionEnabled(actionParams);
            }
        }

        if (isVisible && isEnabled) {
            if (item.items) {
                const subItems = await filterMenuItems(item.items, secData);
                if (subItems.length > 0 || item.action) {
                    filteredItems.push({ ...item, items: subItems });
                }
            } else {
                filteredItems.push(item);
            }
        }
    }

    return filteredItems;
}

export async function BuildMenu() {
    const secData = await getSecurityJson();

    const menu = [
        {
            label: 'Home', icon: <HomeIcon />, id: 1
        },
        {
            label: 'Properties', icon: <HouseIcon />, items: [
                {
                    label: 'CRM', action: 'e:tunimainmodule:act_developer_account', items: [
                        { label: 'Items', icon: <InventoryIcon />, id: 3001 },
                        { label: 'Orders', id: 3006 },
                        { label: 'Storage Items', id: 3002 },
                        { label: 'Documents', icon: <ArticleIcon />, id: 3003 },
                        { label: 'Documents Grid', id: 3007 },
                        { label: 'Physical Items', id: 3004 },
                        { label: 'Warehouses', icon: <WarehouseIcon />, id: 3005 }
                    ]
                },
                {
                    label: 'Schedules', items: [
                        { label: 'Cleaning Schedule', action: 'e:tmaindesktopform:act_cleanersschedule', icon: <CalendarMonthIcon />, id: 201 },
                        { label: 'Checkout Schedule', action: 'v:tmaindesktopform:act_cleanersschedule', icon: <CalendarMonthIcon />, id: 202 },
                        { label: 'Cleaning Default Editor', action: 'v:tmaindesktopform:act_olap_explorer', icon: <Looks4Rounded />, id: 203 }
                    ]
                },
                {
                    label: 'Statistics', action: 'v:tmaindesktopform:act_olap_explorer', items: [
                        { label: 'Sales Rep', icon: <LocalAtmIcon />, id: 11 },
                        { label: 'Pace Rep', icon: <SelfImprovementIcon />, id: 12 },
                        { label: 'Z Report', icon: <MoneyIcon />, id: 13 },
                        { label: 'All Sales Report', id: 14 }
                    ]
                }
            ],
        },
        {
            label: 'Reservations', icon: <BookOnlineIcon />, items: [
                {
                    label: 'Static Boards', action: 'e:tmaindesktopform:act_static_inouts', items: [
                        { label: 'InOut Board', id: 501 },
                        { label: 'In board', id: 502 },
                        { label: 'Out board', id: 503 }
                    ]
                }
            ]
        },
        {
            label: 'Services', action: 'e:tunimainmodule:act_developer_account', icon: <LogoDevIcon />, items: [
                {
                    label: 'Services Log', items: [
                        { label: 'App Log', id: 101 },
                        { label: 'Monitoring View', id: 9001 },
                    ]
                },
                {
                    label: 'PCRMS Log', items: [
                        { label: 'Stays Log', id: 1091 },
                        { label: 'Reservations Log', id: 1092 },
                        { label: 'Property Prices Log', id: 1093 },
                    ]
                },
                {
                    label: 'Properties Functions', items: [
                        { label: 'Sync Properties', id: 9101 },
                        { label: 'Rentals United CM Manager', id: 9102 }
                    ]
                }
            ]
        }
    ];

    return filterMenuItems(menu, secData);
}

export function collectMenuItemsWithId(items, collectedItems = []) {
    for (let item of items) {
        if (item.id) {
            collectedItems.push(item);
        }
        if (item.items) {
            collectMenuItemsWithId(item.items, collectedItems);
        }
    }
    return collectedItems;
}

