import React, { useEffect, useState } from 'react';
import {
    Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
    Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper,
    TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab,
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';

// Helper function for TabPanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function CRMOrderDetails({ orderID, open, onClose }) {
    const [tabValue, setTabValue] = useState(0);
    const [comment, setComment] = useState('');

    useEffect(() => {
        // Fetch initial order details when the dialog opens (orderID or other props)
        if (open) {
            // Fetch order details logic here
        }
    }, [open]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    height: '80vh',
                    width: '90vw',
                },
            }}
        >
            <DialogTitle id="form-dialog-title" sx={{ bgcolor: orangeColor }}>
                Order Details for {orderID}
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="order details tabs">
                    <Tab label="General" />
                    <Tab label="Items" />
                    <Tab label="Documents" />
                    <Tab label="Audit" />
                </Tabs>

                {/* Tab Panels */}
                <TabPanel value={tabValue} index={0}>
                    <Typography variant="h6" gutterBottom>
                        General Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Created By"
                                value="John Doe"
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Order Person"
                                value="Jane Smith"
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Delivery Warehouse"
                                value="Warehouse A"
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Order Date"
                                value="2024-09-10"
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Comments"
                                value={comment}
                                onChange={handleCommentChange}
                                fullWidth
                                multiline
                                rows={3}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Typography variant="h6">Ordered Items</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Map your items here */}
                                <TableRow>
                                    <TableCell>Item 1</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>$100</TableCell>
                                </TableRow>
                                {/* More rows */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Typography variant="h6">Documents</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Date Uploaded</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Map your documents here */}
                                <TableRow>
                                    <TableCell>Document 1</TableCell>
                                    <TableCell>2024-09-01</TableCell>
                                    <TableCell>
                                        <Button variant="contained">Download</Button>
                                    </TableCell>
                                </TableRow>
                                {/* More rows */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <Typography variant="h6">Audit</Typography>
                    <Typography variant="body1">To be implemented</Typography>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

